import React, { createContext, useContext, useState, useEffect } from 'react';
import { Auth, Hub, Amplify } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import amplifyConfig from '../amplifyConfig';

const AuthContext = createContext();

export const useAuth = () => {
	return useContext(AuthContext);
};

function AuthProvider({ children }) {
	const navigate = useNavigate();
	const [user, setUser] = useState(null);
	const [authenticated, setAuthenticated] = useState(false);
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		Amplify.configure(amplifyConfig);
		checkUser();
		const handleSignOut = () => {
			setUser(null);
			setAuthenticated(false);
		};

		const authListener = Hub.listen('auth', ({ payload: { event } }) => {
			switch (event) {
				case 'signIn':
					return checkUser();
				case 'signOut':
					return handleSignOut();

				default:
					break;
			}
		});

		return () => authListener();
	}, []);

	const checkUser = async () => {
		try {
			const userData = await Auth.currentAuthenticatedUser({ bypassCache: true });
			setUser({ ...userData });
			setInitialized(true);
			if (!userData.attributes?.['custom:tenant']) {
				navigate('/auth/onboarding');
				return;
			}
			setAuthenticated(true);
		} catch (err) {
			setUser(null);
			setAuthenticated(false);
			setInitialized(true);
		}
	};

	const forceRefreshSession = async () => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			const session = await Auth.currentSession();

			// Force refresh the tokens
			const newSession = await new Promise((resolve, reject) => {
				user.refreshSession(session.getRefreshToken(), (err, newSession) => {
					if (err) {
						reject(err);
					} else {
						resolve(newSession);
					}
				});
			});

			// Manually set the new session tokens
			user.signInUserSession = newSession;

			// Update the Cognito User Pool local storage
			const userCopy = { ...user, signInUserSession: newSession };
			Auth.storeUserData(userCopy);
			return newSession;
		} catch (error) {
			console.log('Error refreshing token:', error);
		}
	};

	const signIn = async (username, password) => {
		const response = await Auth.signIn(username, password);
		if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
			setUser(response);
		}
		return response;
	};

	const signUp = async (username, password, email) => {
		const response = await Auth.signUp({
			username,
			password,
			attributes: { email, 'custom:role': 'admin' }
		});
		if (typeof response.userConfirmed !== 'undefined' && response.userConfirmed === false) {
			setUser(response);
			response.confirmUser = true;
		}
		return response;
	};

	const signOut = async () => {
		try {
			await Auth.signOut();
		} catch (err) {
			console.error('Error signing out', err);
		}
	};

	const forgotPassword = async username => {
		return Auth.forgotPassword(username);
	};

	const submitForgotPassword = async (username, code, newPassword) => {
		return Auth.forgotPasswordSubmit(username, code, newPassword);
	};

	const completeNewPassword = async newPassword => {
		try {
			const resp = await Auth.completeNewPassword(user, newPassword);
			setUser(resp);
			setAuthenticated(true);
		} catch (err) {
			console.error('Error completing new password', err);
		}
	};

	const confirmSignup = async (code, usr) => {
		return Auth.confirmSignUp(usr, code);
	};

	const resendConfirmationCode = async username => {
		return Auth.resendSignUp(username ?? user.user.username);
	};

	const changePassword = async (oldPassword, newPassword) => {
		const currentUser = await Auth.currentAuthenticatedUser();
		return Auth.changePassword(currentUser, oldPassword, newPassword);
	};

	const getAccessToken = async () => {
		return (await Auth.currentSession()).getIdToken().getJwtToken();
	};

	return (
		<AuthContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				user: {
					displayName: user?.attributes?.email,
					role: user?.attributes?.['custom:role'],
					isSuperadmin: user?.attributes?.['custom:superuser'] === 'true',
					tenant: user?.attributes?.['custom:tenant'],
					email: user?.attributes?.email,
					...user
				},
				method: process.env.REACT_APP_FEDERATED_ID ? 'SSO' : 'login',
				isAuthenticated: authenticated,
				isInitialized: initialized,
				code: user?.challengeName,
				login: signIn,
				register: signUp,
				logout: signOut,
				resetPassword: forgotPassword,
				submitForgotPassword,
				completeNewPassword,
				resendConfirmationCode,
				validateUserSession: checkUser,
				refreshSession: forceRefreshSession,
				confirmSignup,
				changePassword,
				getAccessToken
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
